/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { APP_ROUTES } from 'utils/constants'
import Container from 'components/ui/Container/Container'
import Image from 'next/legacy/image'
import logoImage from 'public/images/logo/cloopio-logo.svg'
import logoMobileImage from 'public/images/logo/cloopio-logo-mobile.svg'
import clsx from 'clsx'
import IconClose from '../Icon/IconClose'
import IconMenu from '../Icon/IconMenu'

export type Props = {
  className?: string
  navigation?: ({ hideMobileMenu }: { hideMobileMenu: () => void }) => React.ReactNode
  extras?: ({ hideMobileMenu }: { hideMobileMenu: () => void }) => React.ReactNode
  modal?: React.ReactNode
}

const Header: React.FC<Props> = ({
  className = undefined,
  navigation = null,
  extras = null,
  modal = null,
}) => {
  const [isMobileHaderActive, setActive] = useState(false)

  const showMobileMenu = () => {
    document.body.classList.add('overflow-hidden')
    setActive(true)
  }

  const hideMobileMenu = () => {
    document.body.classList.remove('overflow-hidden')
    setActive(false)
  }

  // clean up mobile menu
  useEffect(
    () => () => {
      hideMobileMenu()
    },
    []
  )

  return (
    <>
      <header
        className={clsx(
          className,
          'sticky top-0 z-header w-full border-b border-gray-200 bg-white'
        )}
      >
        <Container className="flex h-20 items-center justify-between">
          <div className="flex items-center space-x-16">
            <div className="relative">
              <Link href={APP_ROUTES.HOME} className="-ml-4 block w-28 rounded lg:-ml-9 lg:w-32">
                <Image src={logoImage} alt="Cloopio" />
              </Link>
            </div>
            <div className="hidden lg:block">{navigation?.({ hideMobileMenu })}</div>
          </div>

          <div className="hidden lg:block">{extras?.({ hideMobileMenu })}</div>

          <button
            type="button"
            onClick={showMobileMenu}
            className="relative ml-auto h-8 w-8 lg:hidden"
          >
            <IconMenu size="24" />
          </button>

          <div
            className={clsx(
              'fixed left-0 top-0 h-full w-full overflow-y-auto bg-white',
              isMobileHaderActive ? 'block' : 'hidden'
            )}
          >
            <div className="px-6 pb-4 md:px-10">
              <div className="flex h-20 items-center justify-between">
                <Link href={APP_ROUTES.HOME} className="-ml-8 block w-28">
                  <Image src={logoMobileImage} alt="Cloopio" />
                </Link>

                <button type="button" onClick={hideMobileMenu}>
                  <IconClose size="24" />
                </button>
              </div>
              <div>{navigation?.({ hideMobileMenu })}</div>
              <div className="mt-5">{extras?.({ hideMobileMenu })}</div>
            </div>
          </div>
        </Container>
      </header>

      {modal}
    </>
  )
}

export default Header
