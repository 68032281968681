import React, { InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import { IconComponent } from '@/components/ui/Icon/IconBase'

type Props = {
  Icon?: IconComponent
} & InputHTMLAttributes<HTMLInputElement>

const FormInput: React.FC<Props> = React.forwardRef<HTMLInputElement, Partial<Props>>(
  ({ Icon = undefined, ...props }, ref) => (
    <div className="relative w-full">
      {Icon && (
        <div className="pointer-events-none absolute left-3 top-0 flex h-10 w-6 items-center justify-center text-gray-500">
          <Icon size="24" />
        </div>
      )}
      <input
        ref={ref}
        className={clsx(
          props.className,
          'block h-10 w-full border pb-px pt-0 text-sm leading-none focus:outline-none',
          'rounded-lg focus:ring-2 focus:ring-gray-100',
          !props.disabled && 'border-gray-300 text-gray-800 placeholder-gray-400',
          Icon ? 'pl-11 pr-3' : 'px-3',
          props.disabled && 'border-gray-200 bg-gray-100 text-gray-500'
        )}
        {...props}
      />
    </div>
  )
)

FormInput.displayName = 'FormInput'

export default FormInput
