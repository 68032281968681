import React, { ReactNode, Fragment } from 'react'
import clsx from 'clsx'

import { Listbox } from '@headlessui/react'
import IconCheckmark from '../Icon/IconCheckmark'

type Props = {
  children: ReactNode
  value: Object
  disabled?: boolean
  cy?: string
}

const ListboxOption: React.FC<Props> = ({ children, value, disabled = false, cy = undefined }) => (
  <Listbox.Option as={Fragment} value={value} disabled={disabled} data-cy={cy}>
    {({ active, selected }) => (
      <li
        className={clsx(
          'flex items-center truncate rounded-lg px-4 py-2 text-sm',
          active && 'bg-gray-100 hover:bg-gray-100',
          !disabled && 'cursor-pointer text-gray-800',
          disabled && 'text-gray-300'
        )}
      >
        <span className="mr-3">{children}</span>
        <IconCheckmark className={clsx(!selected && 'opacity-0', 'ml-auto w-4 flex-shrink-0')} />
      </li>
    )}
  </Listbox.Option>
)

export default ListboxOption
