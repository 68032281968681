import React, { ButtonHTMLAttributes, AnchorHTMLAttributes, useMemo } from 'react'
import clsx from 'clsx'

type CommonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'white'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  loading?: boolean
  cy?: string
}

export type ButtonProps = CommonProps & ButtonHTMLAttributes<HTMLButtonElement>
export type AnchorProps = CommonProps & AnchorHTMLAttributes<HTMLAnchorElement>

const isAnchor = (props: ButtonProps | AnchorProps): props is AnchorProps =>
  typeof (props as AnchorProps).href === 'string'

export type Props = ButtonProps | AnchorProps

// eslint-disable-next-line react/require-default-props
const Button: React.FC<Props> = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      variant = 'primary',
      size = 'md',
      children = null,
      className = '',
      loading = false,
      cy = undefined,
      ...otherProps
    } = props

    // eslint-disable-next-line react/destructuring-assignment
    const disabled = isAnchor(props) ? false : props.disabled

    const computedClassName = clsx(
      className,
      'inline-flex relative items-center text-center space-x-1 justify-center border rounded transition-colors duration-200 ease-in-out',
      !disabled && 'focus-element',
      variant === 'primary' && [
        'border-gray-200 bg-primary-600 shadow',
        !disabled && 'hover:bg-primary-700',
      ],
      variant === 'secondary' && [
        'border-transparent bg-gray-700 text-white',
        !disabled && 'hover:bg-gray-900',
      ],
      variant === 'tertiary' && [
        'border-transparent bg-gray-100 text-gray-800',
        !disabled && 'hover:bg-gray-200',
      ],
      variant === 'white' && [
        'border-transparent bg-white text-gray-800',
        !disabled && 'hover:bg-gray-100',
      ],
      disabled && 'opacity-50 cursor-not-allowed',
      size === 'md' && 'h-10 px-5 text-sm font-medium',
      size === 'lg' && 'h-[50px] px-5 text-sm font-medium',
      size === 'sm' && 'min-w-fit min-h-fit text-xs font-medium',
      size === 'xl' && 'h-14 px-10 text-base font-bold'
    )

    const loadingSpinner = useMemo(
      () =>
        loading ? (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-60">
            <div className="h-5 w-5 animate-spin rounded-full border-l-2 border-gray-900" />
          </div>
        ) : null,
      [loading]
    )

    const content = useMemo(() => {
      if (size === 'sm') {
        return <span>{loading ? loadingSpinner : children}</span>
      }

      return (
        <>
          {loadingSpinner}
          {children}
        </>
      )
    }, [loading, children, loadingSpinner, size])

    if (isAnchor(props)) {
      return (
        <a ref={ref} className={computedClassName} {...(otherProps as AnchorProps)}>
          {content}
        </a>
      )
    }
    return (
      // eslint-disable-next-line react/button-has-type
      <button ref={ref} className={computedClassName} data-cy={cy} {...(otherProps as ButtonProps)}>
        {content}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
