import React from 'react'
import clsx from 'clsx'

export interface Props {
  type?: 'error' | 'success'
  children: React.ReactNode
}

const Alert: React.FC<Props> = ({ type = 'error', children }) => (
  <div
    className={clsx(
      'rounded-md px-4 py-3 text-sm font-medium',
      type === 'error' && 'bg-red-50 text-red-600',
      type === 'success' && 'bg-green-50 text-green-600'
    )}
  >
    {children}
  </div>
)

export default Alert
