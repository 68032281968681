import React from 'react'

export type Props = {
  children: React.ReactNode
}

const HowToSteps: React.FC<Props> = ({ children }) => (
  <div className="flex flex-col items-center md:flex-row md:items-start md:justify-center md:space-x-28">
    {children}
  </div>
)

export default HowToSteps
