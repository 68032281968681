import React, { ReactNode } from 'react'
import clsx from 'clsx'

type Props = {
  htmlFor: string
  label?: string
  hideLabel?: boolean
  error?: string | undefined
  required?: boolean
  children: ReactNode
}

const FormGroup: React.FC<Props> = ({
  htmlFor,
  label = undefined,
  hideLabel = false,
  children,
  error = undefined,
  required = false,
}) => (
  <div>
    {label && (
      <label
        className={clsx('mb-1.5 block text-sm leading-none text-gray-600', hideLabel && 'sr-only')}
        htmlFor={htmlFor}
      >
        {label}
        {required && <sup className="select-none text-red-600">*</sup>}
      </label>
    )}
    <div>{children}</div>
    {error && <div className="py-1 text-xs font-medium text-red-600">{error}</div>}
  </div>
)

export default FormGroup
