import React, { useMemo } from 'react'
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import Header from 'components/ui/Header/Header'
import { APP_ROUTES, TYPEFORM } from 'utils/constants'
import { Listbox } from '@headlessui/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { Locale } from 'generated/graphcms'
import Anchor from 'components/ui/Anchor/Anchor'
import ListboxButton from 'components/ui/Listbox/ListboxButton'
import ListboxOptions from 'components/ui/Listbox/ListboxOptions'
import ListboxOption from 'components/ui/Listbox/ListboxOption'
import Button from '@/components/ui/Button/Button'
import { logGoogleAnalyticEvent, logGoogleTagManagerEvent } from 'utils/tracking'
import useTypeformTracker from 'hooks/useTypeformTracker'

interface Props {
  locationLinkSuffix?: string
}

const HeaderCombined: React.FC<Props> = ({ locationLinkSuffix = '' }) => {
  const router = useRouter()
  const { locale, pathname, asPath, query } = router

  const languages = Object.values(Locale) as Locale[]

  const { t } = useTranslation('common')

  const switchLocaleTo = (newLocale: Locale) => {
    logGoogleTagManagerEvent('language_switch', { newLocale })
    document.cookie = `NEXT_LOCALE=${newLocale}; max-age=31536000; path=/`
    return router.push({ pathname, query }, asPath, { locale: newLocale })
  }

  const { open: openContactForm } = useMemo(
    () => createPopup(locale === Locale.De ? TYPEFORM.CONTACT_ID_DE : TYPEFORM.CONTACT_ID),
    [locale]
  )

  const { open: openPartnerForm } = useMemo(() => createPopup(TYPEFORM.BECOME_PARTNER_ID), [])

  const { onClose, onSubmit } = useTypeformTracker()

  const { open: openRequestQuoteForm } = useMemo(
    () =>
      createPopup(locale === Locale.De ? TYPEFORM.REQUEST_QUOTE_ID_DE : TYPEFORM.REQUEST_QUOTE_ID, {
        hidden: {
          language: locale || Locale.En,
        },
        onClose,
        onSubmit,
      }),
    [locale, onClose, onSubmit]
  )

  const requestQuoteForm = () => {
    logGoogleTagManagerEvent('quote_request_started')
    openRequestQuoteForm()
  }

  const getInTouch = () => {
    logGoogleAnalyticEvent('free_consultation')
    openContactForm()
  }

  const navigation = () => (
    <div className="flex flex-col space-y-5 lg:flex-row lg:space-x-10 lg:space-y-0">
      <Link href={APP_ROUTES.VENUES + locationLinkSuffix} passHref legacyBehavior>
        <Anchor>{t('header.navigation.venues')}</Anchor>
      </Link>
      <Link href={APP_ROUTES.ABOUT} passHref legacyBehavior>
        <Anchor>{t('header.navigation.about')}</Anchor>
      </Link>
      <Link href={APP_ROUTES.BLOG} passHref legacyBehavior>
        <Anchor>{t('header.navigation.blog')}</Anchor>
      </Link>
    </div>
  )

  const extras = () => (
    <div className="flex flex-col space-y-5 text-sm lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
      <div className="flex flex-col space-y-5 lg:flex-row lg:items-center lg:space-x-6 lg:space-y-0">
        <Button onClick={openPartnerForm} variant="tertiary">
          {t('header.venueContactButton')}
        </Button>
        <Button onClick={requestQuoteForm}>{t('header.requestQuoteButton')}</Button>
        <Button onClick={getInTouch} variant="tertiary">
          {t('header.consultationButton')}
        </Button>
        <div className="inline-flex">
          <Listbox as="div" className="relative" value={locale} onChange={switchLocaleTo}>
            {({ open }) => (
              <>
                <ListboxButton variant="secondary" open={open}>
                  {t(`language.${locale}`)}
                </ListboxButton>
                <ListboxOptions>
                  {languages.map((language) => (
                    <ListboxOption key={language} value={language}>
                      {t(`language.${language}`)}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  )

  return <Header navigation={navigation} extras={extras} />
}

export default HeaderCombined
