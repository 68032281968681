import { useRef } from 'react'
import { useRouter } from 'next/router'
import {
  logFacebookEvent,
  logGoogleAdsConversion,
  logGoogleAnalyticEvent,
  logLinkedInInsightsConversion,
  triggerHotJarEvent,
} from 'utils/tracking'
import { Locale } from 'generated/graphcms'
import { APP_ROUTES } from 'utils/constants'

const useTypeformTracker = () => {
  const { locale = Locale.En, push } = useRouter()

  const hasSubmitted = useRef(false)

  const onSubmit = () => {
    hasSubmitted.current = true
    logGoogleAdsConversion('AW-10892640527/r9qrCPerpM4DEI-Cgsoo')
    logLinkedInInsightsConversion(9694169)
    logGoogleAnalyticEvent('quote_request_completed')
    logFacebookEvent('QuoteRequestComplete')
  }

  const onClose = () => {
    if (hasSubmitted.current) {
      return
    }

    const event = locale === Locale.De ? 'cancel_forced_signup_de' : 'cancel_forced_signup'
    logGoogleAnalyticEvent('quote_request_canceled')
    triggerHotJarEvent(event)
  }

  const onEndingButtonClick = () => {
    logGoogleAnalyticEvent('quote_request_ending_button_click')
    push({ pathname: APP_ROUTES.HOME })
  }

  return {
    onClose,
    onSubmit,
    onEndingButtonClick,
  }
}

export default useTypeformTracker
