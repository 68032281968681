import React, { useEffect } from 'react'
import clsx from 'clsx'
import { FooterData } from '@/components/layout/AppShell/getFooterData'
import { useTranslation } from 'next-i18next'
import IconClose from '@/components/ui/Icon/IconClose'
import { useRecoilState } from 'recoil'
import { bannerClosed } from 'store/tracking'
import Newsletter from '@/components/shared/Newsletter/Newsletter'
import Steps from '@/components/shared/Steps/Steps'
import { useRouter } from 'next/router'
import Head from 'next/head'
import HeaderCombined from './AppShell/HeaderCombined'
import Footer from './AppShell/Footer'
import { BASE_URL } from '../../utils/constants'

interface Props {
  children: React.ReactNode
  isPublic?: boolean
  footerData?: FooterData
}

const Layout: React.FC<Props> = ({ children, isPublic = false, footerData = { areas: [] } }) => {
  const {
    t,
    i18n: { exists },
  } = useTranslation()

  const [showBanner, setShowBanner] = useRecoilState(bannerClosed)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShowBanner(sessionStorage?.getItem('banner_closed') !== 'true')
    }
  }, [setShowBanner])

  const closeBanner = () => {
    setShowBanner(false)
    sessionStorage.setItem('banner_closed', 'true')
  }

  const locationLinkSuffix =
    footerData.areas.length === 1 ? `/${footerData.areas[0].slug}` : undefined

  const { locale, asPath, defaultLocale } = useRouter()

  const localePrefix = locale !== defaultLocale ? `/${locale}` : ''

  const canonical = `${BASE_URL}${localePrefix}${asPath}`
  const alternateEN = `${BASE_URL}${asPath}`
  const alternateDE = `${BASE_URL}/de${asPath}`

  const shouldShowBanner = showBanner && exists('header.banner')

  return (
    <div className="min-h-screen">
      <Head>
        <link rel="canonical" href={canonical} />
        <link rel="alternate" hrefLang="en" href={alternateEN} />
        <link rel="alternate" hrefLang="de" href={alternateDE} />
        <meta property="og:canonical" content={canonical} />
      </Head>
      {shouldShowBanner && (
        <div className="relative bg-primary-600 px-10 py-4 text-center font-bold">
          {t('header.banner')}
          <button
            type="button"
            onClick={closeBanner}
            className="absolute right-1 top-2 p-2 ring-0 focus:outline-none md:right-2 md:top-2"
          >
            <IconClose
              size="24"
              className="text-gray-900 transition-colors duration-300 ease-in-out hover:text-gray-400"
            />
          </button>
        </div>
      )}
      <HeaderCombined locationLinkSuffix={locationLinkSuffix} />
      <main className={clsx(!isPublic && 'mt-6 md:mt-12')}>{children}</main>

      <Steps />
      <Newsletter />
      <Footer data={footerData} />
    </div>
  )
}

export default Layout
